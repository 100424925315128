import React from "react";
import styled from "styled-components";
import { colors, spacers, breakpoints } from "../styles/variables";

interface TitleProps {
  children: React.ReactNode;
  spacingTop?: boolean;
}

const TitleElem = styled.h2`
  color: ${colors.brand};
  hyphens: auto;
  word-wrap: break-word;

  &.spacingTop {
    margin-top: ${spacers.s}px;

    @media screen AND (min-width: ${breakpoints.m}px) {
      margin-top: ${spacers.m}px;
    }

    @media screen AND (min-width: ${breakpoints.l}px) {
      margin-top: ${spacers.l}px;
    }
  }
`;

const Title: React.FC<TitleProps> = ({ children, spacingTop }) => (
  <TitleElem className={spacingTop ? "spacingTop" : ""}>{children}</TitleElem>
);

export default Title;
