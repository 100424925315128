import React from "react";
import styled from "styled-components";
import { colors, spacers } from "../styles/variables";

const StyledButton = styled.a`
  display: block;
  background: ${colors.brand};
  border: 2px solid ${colors.brand};
  margin: 0 auto;
  color: white;
  text-transform: uppercase;
  padding: 8px ${spacers.s}px;
  max-width: 300px;
  width: fit-content;
  transition: all 200ms;

  &:hover {
    color: ${colors.brand};
    background: white;
    text-decoration: none;
    transform: scale(1.03);
  }
`;

interface ButtonProps {
  url: string;
  text: string;
}

const Button: React.FC<ButtonProps> = ({ url, text }) => (
  <StyledButton href={url}>{text}</StyledButton>
);

export default Button;
