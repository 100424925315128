import React from "react";
import styled from "styled-components";
import { Background } from "react-imgix";
import { imgix } from "../styles/variables";

interface ProjektImageProps {
  itemImg: string;
}

const ProjektDiv = styled.div`
  height: 390px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;

  > div {
    width: 100%;
    height: 100%;
    background-position: center;
  }
`;

const ProjektItem: React.FC<ProjektImageProps> = ({ itemImg }) => {
  return (
    <ProjektDiv>
      <Background src={itemImg} imgixParams={imgix.gridImgParams}></Background>
    </ProjektDiv>
  );
};

export default ProjektItem;
