import React from "react";
import DefaultLayout from "../layouts/default";
import PartialPageImgHeader from "../components/PartialPageImgHeader";
import Title from "../components/Title";
import InhaltsText from "../components/InhaltsText";
import Wrapper from "../components/Wrapper";
import HorizontalWrapper from "../components/HorizontalWrapper";
import GridWrapper from "../components/GridWrapper";
import ProjekteGrid from "../components/ProjekteGrid";
import ProjektImage from "../components/ProjektImage";
import HalfGrid from "../components/HalfGrid";
import Button from "../components/Button";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

interface ProjektQueryProps {
  data: {
    cms: {
      entry: {
        title: string;
        inhaltstext: string;
        inhaltstext2: string;
        mainImageProjekt: [{ url: string }];
        projektBild: [{ url: string }];
        projektPdf: [{ url: string }];
      };
    };
  };
}

export const projektQuery = graphql`
  query($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        ... on CMS_inhalt_projekt_Entry {
          inhaltstext
          inhaltstext2
          mainImageProjekt {
            url
          }
          projektBild {
            url
          }
          projektPdf {
            url
          }
        }
      }
    }
  }
`;

const Projekt: React.FC<ProjektQueryProps> = ({ data }) => {
  const entry = data.cms.entry;

  return (
    <DefaultLayout>
      <Helmet>
        <title>{entry.title} – Niederegger AG</title>
      </Helmet>
      <PartialPageImgHeader image={entry.mainImageProjekt[0].url} />
      <HorizontalWrapper>
        <Title spacingTop>{entry.title}</Title>
      </HorizontalWrapper>
      <Wrapper>
        <HalfGrid>
          <InhaltsText html={entry.inhaltstext} />
          <InhaltsText html={entry.inhaltstext2} />
        </HalfGrid>
      </Wrapper>

      {entry.projektBild.length > 0 ? (
        <>
          <HorizontalWrapper>
            <Title spacingBottom>Projektbilder</Title>
          </HorizontalWrapper>
          <GridWrapper>
            <ProjekteGrid>
              {entry.projektBild.map((bild) => (
                <ProjektImage itemImg={bild.url} key={bild.url} />
              ))}
            </ProjekteGrid>
          </GridWrapper>
        </>
      ) : (
        ""
      )}

      {entry.projektPdf.length > 0 ? (
        <Wrapper>
          <Button url={entry.projektPdf[0].url} text="PDF Download" />
        </Wrapper>
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default Projekt;
